import * as React from 'react';
import { graphql } from 'gatsby';
import { TrendLayout } from '../../components/entities/trend-layout';
import { createGatsbyPageComponent } from '@shapeable/web';

export default createGatsbyPageComponent('Trend', { layout: TrendLayout });

export const trendQuery = graphql`
  query TrendQuery($id: ID!) {
    platform {
      trend(id: $id) {
        __typename gptName _schema { label pluralLabel }
        id slug name __typename outlineNumber
        color { __typename id slug name value }
        description { text }
        intro { text }
        openGraph { 
          title description { plain }
          image { url thumbnails { large { url } } }
        }
        banner {
          id
          thumbnail { url } 
          image { url } 
        }
        feedEntries {
          id slug name __typename url
          openGraph { 
            title description { plain }
            image { url thumbnails { large { url } } }
          }
        }
        posts {
          id slug name __typename path _schema { label pluralLabel }
          type { id name slug }
          openGraph { 
            title description { plain }
            image { url thumbnails { large { url } } }
          }
        }
        topics {
          id name outlineNumber slug path __typename 
          # trendIntro { text }
          intro { text(truncate: {ellipsis: "...", words: 45}) }
          color { id name value } 
          banner {
            id
            thumbnail { url static { childImageSharp { gatsbyImageData(placeholder: NONE) } } } 
            image { url } 
          }
        }
        embeds {
          posts {
            id name slug path
            openGraph {
              title description { plain }
              image { url type static { childImageSharp { gatsbyImageData(placeholder: NONE) } } }
            }
          }
        }
        # connectedTrends {
        #   id name slug path outlineNumber color { value }
        # }
        # connectedTopics {
        #   id name slug path outlineNumber color { value }
        # }
        # connectedSubTopics {
        #   id name slug path outlineNumber color { value }
        # }
        # connectedPages {
        #   id name slug path type { name }
        # }
        pages {
          __typename id name path slug title subtitle pretitle 
          _schema { label pluralLabel }
          openGraph { id image { id url thumbnails { large { id url } } } }
          orderNumber
          type { id name slug }
        }
      }
    }
  }
`;
