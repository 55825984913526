import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, IconComponent, Page, Post, Shapeable, ContentNode as TContentNode } from '@shapeable/types';
import { Trend } from '@shapeable/copper-connect-types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentEntityIntro, ContentNode, EntityGrid, NextPageLink, useEntity, useView, DottedLine, EntityMainHeader, EntityBanner, EntityCard, useSearch, SearchBar } from '@shapeable/ui';
import { TabSpec, Tabs } from '../elements/tabs';
import { classNames } from '@shapeable/utils';
const cls = classNames('trend-main-layout');
import { compact } from 'lodash';
import { ExplorerView } from '../elements/explorer-view';
import { ContentTabs } from '../elements/content-tabs';
import { EntityMainBannerLayout } from './entity-main-banner-layout';
import { AiCIconGlyph, AiUpperCaseIconGlyph } from '@shapeable/icons';
import { GptLayout } from '@shapeable/gpt';
import { CommodityGroupDepositList } from './commodity-group-deposit-list';
import { useCommodityGroups } from '../../hooks/use-commodity-groups';

// -------- Types -------->

export type TrendView = 'copper-ai' | 'overview' | 'deposits' | 'library';

export type TrendMainLayoutProps = Classable & HasChildren & { 
  entity?: Trend;
};

export const TrendMainLayoutDefaultProps: Omit<TrendMainLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-size: 1em;
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(1)};
  `,
  desktop: css`
    gap: ${theme.UNIT(4)};
  `,
});

const BannerContainerStyles = breakpoints({
  base: css`
    padding: 0 ${theme.UNIT(5)};
  `,
});

const BannerStyles = breakpoints({
  base: css`
    
  `,
});

const SectionStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(5)};
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(5)};
  `,
});

const ContentTitleStyles = breakpoints({
  base: css`
    
  `,
});

const DescriptionStyles = breakpoints({
  base: css`
    
  `,
});


const TabsStyles = breakpoints({
  base: css`
    width: 100%;
    padding-top: ${theme.UNIT(1)};
    margin-top: ${theme.UNIT(2)}; 
  `,
});

const IntroStyles = breakpoints({
  base: css`
    font-size: 1.35em;
  `,
});

const NextLinkStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(5)};
    
  `,
});

const EntityGridStyles = breakpoints({
  base: css`
    .shp--card {
      background-color: ${theme.COLOR('light')};
    }
  `,
});

const GptLayoutStyles = breakpoints({
  base: css`
    .shp--gpt-prompt {
      &:first-child {
        border-top: none;
      }
    };
  `,
});

const DepositsStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
  `,
});

const SearchBarStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    BannerContainer: styled.div`${BannerContainerStyles}`,
      Banner: styled(EntityBanner)`${BannerStyles}`,
    Section: styled.section`${SectionStyles}`,
      ContentTitle: styled.h2`${ContentTitleStyles}`,
      Description: styled(ContentNode)`${DescriptionStyles}`,
      Intro: styled(ContentEntityIntro)`${IntroStyles}`,

    Deposits: styled(CommodityGroupDepositList)`${DepositsStyles}`,

    SearchBar: styled(SearchBar)`${SearchBarStyles}`,

    EntityGrid: styled(EntityGrid)`${EntityGridStyles}`,
    Tabs: styled(ContentTabs)`${TabsStyles}`,

    NextLink: styled(NextPageLink)`${NextLinkStyles}`,

    GptLayout: styled(GptLayout)`${GptLayoutStyles}`,
};


export const TrendMainLayout: Shapeable.FC<TrendMainLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);

  const { feedEntries = [], pages = [], posts = [], citations = [], color } = entity;

  const augmentedPosts = posts.map((post: Post) => {
    const postType = post?.type?.name;
    return {
      ...post,
      schema: {
        label: postType,
        pluralLabel: postType,
      }
    }
  })

  const augmentedPages = pages.map((page: Page) => {
    const pageType = page?.type?.name;
    return {
      ...page,
      _schema: {
        label: pageType,
      }
    }
  })

  const libraryItems = compact([...augmentedPosts, ...feedEntries, ...augmentedPages, ...citations ]);

  const hasLibrary = !!libraryItems.length;

  const { description } = entity;

  const isDiscoveryTrend = entity.slug === 'discovery';

  const { searchTerm, setSearchTerm, filteredItems } = useSearch(libraryItems);

  const onChange = (name: string) => { 
    setSearchTerm(name);
   };

  const tabs: TabSpec<TrendView>[] = compact([
    {
      id: 'copper-ai',
      icon: <AiUpperCaseIconGlyph size={30} />
    },
    {
      id: 'overview',
      label: 'Overview',
    },
    isDiscoveryTrend &&
    {
      id: 'deposits',
      label: 'Deposits',
    },
    hasLibrary &&
    {
      id: 'library',
      label: 'Library',
    },
  ]);

  const { activeView, setActiveView } = useView<TrendView>(`trend-${entity.slug}`, `${isDiscoveryTrend ? 'deposits' : 'overview'}`);

  const commodityGroups = useCommodityGroups();
  const copper = commodityGroups.find(item => item.slug === 'copper');

  const hasDeposits = !!copper;

  return (
    <My.Container className={cls.name(className)}>
      <My.BannerContainer>
        <My.Banner 
          variant='overlay'
          entity={entity}
          customAspectRatio={{ base: 16 / 9, desktop: 21 / 9 }}
          entityValueProps={{ showImage: false, showOutlineNumber: true, outlineBubble: { numberSize: 0.6, showBorder: true, showImage: false }}}
          >
        </My.Banner>
      </My.BannerContainer>
      {
        tabs.length > 1 &&
        <My.Tabs onTabClick={setActiveView} activeTab={activeView} ContentTabs={tabs} color={color.value} variant='suiss' />
      }
      <ExplorerView>
      {
        activeView === 'copper-ai' && 
        <My.Section>
          <My.GptLayout entity={entity} />
        </My.Section>
      }
      {
        activeView === 'overview' && 
        <My.Section>
          <My.Intro entity={entity} />
          <My.Description entity={description as TContentNode} />
        </My.Section>
      }
       {
        activeView === 'deposits' &&
        <My.Section>
          {
            hasDeposits && 
            <CommodityGroupDepositList entity={copper} />
          }
        </My.Section>
      }
      {
        activeView === 'library' && 
        <My.Section>
          <My.SearchBar variant='allBorders' searchTerm={searchTerm} onChange={onChange} placeholder={`Search Library`}/>
          <My.EntityGrid items={filteredItems}/>
        </My.Section>
      }
      <DottedLine height={2} />
      <My.NextLink />
      </ExplorerView>
    </My.Container>
  )
};

TrendMainLayout.defaultProps = TrendMainLayoutDefaultProps;
TrendMainLayout.cls = cls;